































import Vue from "vue";
import { mapState } from "vuex";
import { dispatch, ProductName } from "@/store";

export default Vue.extend({
  computed: {
    ...mapState("assets", {
      oss: "oss"
    }),
    ...mapState("app", {
      isCN: "isCN",
      isMobile: "isMobile",
      products: "products"
    }),
    list() {
      const list = [
        {
          hidden: !this.isCN,
          name: this.$t("AMNeon80.name"),
          desc: this.$t("AMNeon80.desc"),
          tag: "AM NEON 80",
          code: "AM NEON 80",
          img: this.oss.products_am_neon_80
        },
        {
          hidden: !this.isCN,
          name: "冰银轴 Pro V2 铂金限定版",
          desc: this.$t("icySilverSwitchProV2.desc"),
          tag: "ICY SILVER SWITCH PRO V2",
          code: "ICY SILVER SWITCH PRO V2",
          img: this.oss.products_icy_silver_switch_pro_v2_2
        },
        {
          name: this.$t("AMEmptinessCharger.name"),
          desc: this.$t("AMEmptinessCharger.desc"),
          tag: "AM EMPTINESS CHARGER",
          code: "AM EMPTINESS CHARGER",
          img: this.oss.products_am_emptiness_charger
        },
        {
          name: this.$t("cyberboardGoldPaisley.name"),
          tag: "CYBERBOARD GOLD PAISLEY",
          code: "CYBERBOARD GOLD PAISLEY",
          img: this.oss.products_cyberboard_gold_paisley
        },
        {
          name: this.$t("AMAfaBlade.name"),
          desc: this.$t("AMAfaBlade.desc"),
          tag: "AM AFA BLADE",
          code: "AM AFA BLADE",
          img: this.oss.products_am_afa_blade
        },
        {
          name: this.$t("icySilverSwitchProV2.name"),
          desc: this.$t("icySilverSwitchProV2.desc"),
          tag: "ICY SILVER SWITCH PRO V2",
          code: "ICY SILVER SWITCH PRO V2",
          img: this.oss.products_icy_silver_switch_pro_v2
        },
        {
          name: this.$t("AMRGB65.name"),
          desc: this.$t("AMRGB65.desc"),
          tag: "AM RGB 65",
          code: "AM RGB 65",
          img: this.oss.products_am_rgb_65
        },
        {
          hidden: this.isCN,
          name: "ACCESSORIES",
          desc: "AM Keyboard & CYBERMAT",
          tag: "",
          code: "",
          img: this.oss.products_cyberboard_typing_kit,
          learnMore: () => {
            window.open(
              "https://store.angrymiao.com/collections/accessories",
              "_blank"
            );
          }
        },
        {
          hidden: !this.isCN,
          name: this.$t("cosmoKillerDune.name"),
          desc: this.$t("cosmoKillerDune.desc"),
          tag: "COSMO KILLER DUNE",
          code: "COSMO KILLER DUNE",
          img: this.oss.products_cosmo_killer_dune
        },
        {
          name: this.$t("AM65LessR2.name"),
          desc: this.$t("AM65LessR2.desc"),
          tag: "AM 65 LESS R2",
          code: "AM 65 LESS R2",
          img: this.oss.products_am_65_less_r2
        },
        {
          name: this.$t("AMRelic80.name"),
          desc: this.$t("AMRelic80.desc"),
          tag: "AM RELIC 80",
          code: "AM RELIC 80",
          img: this.oss.products_am_relic_80
        },
        {
          name: this.$t("AMEmptinessPhonecase.name"),
          desc: this.$t("AMEmptinessPhonecase.desc"),
          tag: "AM EMPTINESS PHONECASE",
          code: "AM EMPTINESS PHONECASE",
          img: this.oss.products_am_emptiness_phonecase
        },
        {
          name: this.$t("cybercharge35.name"),
          desc: this.$t("cybercharge35.desc"),
          tag: "CYBERCHARGE 35",
          code: "CYBERCHARGE 35",
          img: this.oss.products_cybercharge_35
        },
        {
          name: this.$t("cyberboardR4.name"),
          desc: this.$t("cyberboardR4.desc"),
          tag: "CYBERBOARD R4",
          code: "CYBERBOARD R4",
          img: this.isCN
            ? this.oss.products_cyberboard_r4
            : this.oss.products_cyberboard_r4_en
        },
        {
          name: this.$t("icySilverSwitchPro.name"),
          desc: this.$t("icySilverSwitchPro.desc"),
          tag: "ICY SILVER SWITCH PRO",
          code: "ICY SILVER SWITCH PRO",
          img: this.oss.products_icy_silver_switch_pro
        },
        {
          name: this.$t("AMAfaR2.name"),
          desc: this.$t("AMAfaR2.desc"),
          tag: "AM AFA R2",
          code: "AM AFA R2",
          img: this.oss.products_am_afa_r2
        },
        {
          name: this.$t("cosmoKillerR2.name"),
          desc: this.$t("cosmoKillerR2.desc"),
          tag: "COSMO KILLER R2",
          code: "COSMO KILLER R2",
          img: this.oss.products_cosmo_killer_r2
        },
        {
          name: this.$t("carpioAMEditon.name"),
          desc: this.$t("carpioAMEditon.desc"),
          tag: "CARPIO AM EDITON",
          code: "CARPIO AM EDITON",
          img: this.oss.products_carpio_am_editon
        },
        {
          hidden: !this.isCN,
          name: this.$t("AM65LessFading.name"),
          desc: this.$t("AM65LessFading.title01"),
          tag: "AM 65 LESS FADING",
          code: "AM 65 LESS FADING",
          img: this.oss.products_am_65_less_fading
        },
        {
          name: this.$t("AM65Less.name"),
          desc: this.$t("AM65Less.desc"),
          tag: "AM 65 LESS",
          code: "AM 65 LESS",
          img: this.oss.products_am_65_less
        },
        {
          name: this.$t("cosmoKiller.name"),
          desc: this.$t("cosmoKiller.desc"),
          tag: "COSMO KILLER",
          code: "COSMO KILLER",
          img: this.oss.products_cosmo_killer
        },
        {
          name: this.$t("AMAfa.name"),
          desc: this.$t("AMAfa.desc"),
          tag: "AM AFA",
          code: "AM AFA",
          img: this.oss.products_am_afa
        },
        {
          name: this.$t("cberboardTypingKit.name"),
          desc: this.$t("cberboardTypingKit.desc"),
          tag: "CYBERBOARD TYPING KIT",
          code: "CYBERBOARD TYPING KIT",
          img: this.oss.products_cyberboard_typing_kit
        },
        {
          name: this.$t("hover.name"),
          desc: this.$t("hover.desc"),
          tag: "HOVER",
          code: "HOVER",
          img: this.oss.products_hover
        },
        {
          name: this.$t("cyberblade.name"),
          desc: this.$t("cyberblade.desc"),
          tag: "CYBERBLADE",
          code: "CYBERBLADE",
          img: this.oss.products_cyberblade
        },
        {
          name: this.$t("AMHatsuBattleship.name"),
          desc: this.$t("AMHatsuBattleship.desc"),
          tag: "AM HATSU BATTLESHIP",
          code: "AM HATSU BATTLESHIP",
          img: this.oss.products_am_hatsu_battleship
        },
        {
          name: this.$t("backToTheFutureR2.name"),
          desc: this.$t("backToTheFutureR2.desc"),
          tag: "BACK TO THE FUTURE R2",
          code: "BACK TO THE FUTURE R2",
          img: this.oss.back_to_the_future_r2_05
        },
        {
          name: this.$t("emergenceKeycaps.name"),
          desc: this.$t("emergenceKeycaps.desc"),
          tag: "EMERGENCE KEYCAPS",
          code: "EMERGENCE KEYCAPS",
          img: this.oss.products_emergence_keycaps
        },
        {
          name: this.$t("AMHatsu.name"),
          desc: this.$t("AMHatsu.desc"),
          tag: "AM HATSU",
          code: "AM HATSU",
          img: this.oss.products_am_hatsu
        },
        {
          name: this.$t("cybermatR2.name"),
          desc: this.$t("cybermatR2.desc"),
          tag: "CYBERMAT R2",
          code: "CYBERMAT R2",
          img: this.oss.products_cybermat_r2
        },
        {
          name: this.$t("glacierKeycapSet.name"),
          desc: this.$t("glacierKeycapSet.desc"),
          tag: "GLACIER KEYCAP SET",
          code: "GLACIER KEYCAP SET",
          img: this.oss.products_glacier_keycap_set
        },
        {
          name: this.$t("icySilverSwitch.name"),
          desc: this.$t("icySilverSwitch.desc"),
          tag: "ICY SILVER SWITCH",
          code: "ICY SILVER SWITCH",
          img: this.oss.products_icy_silver_switch
        },
        {
          name: this.$t("products.AngryMiao021CName"),
          desc: this.$t("AngryMiao021C.desc"),
          tag: "ANGRY MIAO 021C",
          code: "ANGRY MIAO 021C",
          img: this.oss.products_angry_miao_021c
        },
        {
          name: this.$t("ext.name"),
          desc: this.$t("ext.desc"),
          tag: "EXT",
          code: "EXT",
          img: this.oss.products_ext
        },
        {
          name: this.$t("cybercharge.name"),
          desc: this.$t("cybercharge.desc"),
          tag: "CYBERCHARGE",
          code: "CYBERCHARGE",
          img: this.oss.products_cybercharge
        },
        {
          name: this.isMobile
            ? "CYBERBOARD R2 <br />LE SMOKING"
            : "CYBERBOARD R2 LE SMOKING",
          desc: this.$t("cyberboardR2.desc"),
          tag: "CYBERBOARD R2",
          code: "CYBERBOARD R2",
          img: this.oss.products_cyberboard_r2
        },
        {
          name: this.$t("cyberboardTerminal.name"),
          desc: this.$t("cyberboardTerminal.desc"),
          tag: "CYBERBOARD TERMINAL",
          code: "CYBERBOARD TERMINAL",
          img: this.oss.products_cyberboard_terminal
        },
        {
          name: this.$t("cyberboardR3.name"),
          desc: this.$t("cyberboardR3.desc"),
          tag: "CYBERBOARD R3",
          code: "CYBERBOARD R3",
          img: this.oss.products_cyberboard_r3
        },
        {
          name: this.$t("cyberboardXMAS.name"),
          desc: this.$t("cyberboardXMAS.desc"),
          tag: "CYBERBOARD XMAS",
          code: "CYBERBOARD XMAS",
          img: this.oss.products_cyberboard_xmas
        },
        {
          name: this.$t("cyberboardGB.name"),
          desc: this.$t("cyberboardGB.desc"),
          tag: "CYBERBOARD GB",
          code: "CYBERBOARD GB",
          img: this.oss.products_cyberboard_gb
        },
        {
          name: this.$t("cyberboardGlacierR2.name"),
          desc: this.$t("cyberboardGlacierR2.desc"),
          tag: "CYBERBOARD GLACIER R2",
          code: "CYBERBOARD GLACIER R2",
          img: this.oss.products_cyberboard_glacier_r2
        },
        {
          name: this.$t("cyberboard.name"),
          desc: this.$t("cyberboard.desc"),
          tag: "CYBERBOARD",
          code: "CYBERBOARD",
          img: this.oss.products_cyberboard
        },
        {
          name: this.$t("cybermat.name"),
          desc: this.$t("cybermat.desc"),
          tag: "CYBERMAT",
          code: "CYBERMAT",
          img: this.oss.products_cybermat
        },
        {
          name: this.$t("cyberswitch.name"),
          desc: this.$t("cyberswitch.desc"),
          tag: "CYBERSWITCH",
          code: "CYBERSWITCH",
          img: this.oss.products_cyberswitch
        },
        {
          name: this.$t("products.timeMachineName"),
          desc: this.$t("timeMachine.desc"),
          tag: "TIME MACHINE",
          code: "TIME MACHINE",
          img: this.oss.products_time_machine_02
        },
        {
          name: this.$t("products.keyset02"),
          desc: this.$t("products.keysetDesc02"),
          tag: "DANCING CAT",
          code: "DANCING CAT",
          img: this.oss.products_dancing_cat
        },
        {
          name: this.$t("theChimneyCat.name"),
          desc: this.$t("theChimneyCat.desc"),
          tag: "THE CHIMNEY CAT",
          code: "THE CHIMNEY CAT",
          img: this.oss.products_the_chimney_cat
        },
        {
          name: this.$t("cybercoin.name"),
          desc: this.$t("cybercoin.desc"),
          tag: "CYBERCOIN",
          code: "CYBERCOIN",
          img: this.oss.products_cybercoin
        },
        {
          name: "AMTOYS",
          desc: this.$t("products.amtoysDesc"),
          tag: "AMTOYS",
          code: "AMTOYS",
          img: this.oss.products_amtoys
        }
      ].sort((a, b) => {
        const productA = this.products.find(
          (product: any) => product.code === a.code
        );
        const productB = this.products.find(
          (product: any) => product.code === b.code
        );
        if (productA) {
          const statusKey = this.isCN ? "cn_status" : "overseas_status";
          const priorityKey = this.isCN ? "cn_priority" : "overseas_priority";
          if (productB) {
            if (
              (["inStock", "inStock", "GB"].includes(productA[statusKey]) &&
                ["inStock", "inStock", "GB"].includes(productB[statusKey])) ||
              (!["inStock", "inStock", "GB"].includes(productA[statusKey]) &&
                !["inStock", "inStock", "GB"].includes(productB[statusKey]))
            )
              return (
                (productB[priorityKey] || 0) - (productA[priorityKey] || 0)
              );
          }
          if (["inStock", "inStock", "GB"].includes(productA[statusKey])) {
            return -1;
          }
        }
        return 0;
      });

      console.log(this.products);
      return list;
    }
  },
  methods: {
    onLearnMore(name?: ProductName, learnMore?: () => {}) {
      name && dispatch.learnMore(name);
      learnMore && learnMore();
    }
  }
});
